// function reserveModal() {
//   $("a[name='agree-toggle'], button[name='agree-toggle']").click(function() {
//     $("#agree-modal").toggleClass("is-active");
//     $("html").toggleClass("is-clipped");
//
//   });
// }


function reserveModal() {
  $("a[name='agree-close'], button[name='agree-close']").click(function() {
    $("#agree-modal").removeClass("is-active");
    $("html").removeClass("is-clipped");
  });
  $("a[name='agree-open']").click(function() {
    $("#agree-modal").addClass("is-active");
    $("html").addClass("is-clipped");
  });


}





function reserveModalNow() {
  $("#agree-modal").toggleClass("is-active");
  $("html").toggleClass("is-clipped");
}



$(function() {
  reserveModal();
  // reserveModalNow();
});
